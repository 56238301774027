import { graphql } from "gatsby"
import React, { useState } from "react"
import { Container } from "react-bootstrap"
import { PageLayout, PageTitle, ProjectLink } from "../components"
import { SEO, Utils } from "../utils"

export default ({ data }) => {
  const [state, setState] = useState({
    filteredData: [],
    query: "",
  })

  const allFeaturedImages = data.allFile.edges || []
  const allPosts = data.allMarkdownRemark.edges || []
  const regex = /\/[projets].*\/|$/
  const featuredImageMap = Utils.getImageMap(allFeaturedImages, regex)

  // const handleChange = e => {
  //   const query = e.target.value

  //   const filteredData = allPosts.filter(post => {
  //     // query will run on the following fields
  //     const { description, title, tags, author } = post.node.frontmatter
  //     // standardize query
  //     const stdQuery = query.toLowerCase()
  //     return (
  //       post.node.excerpt.toLowerCase().includes(stdQuery) ||
  //       (description && description.toLowerCase().includes(stdQuery)) ||
  //       title.toLowerCase().includes(stdQuery) ||
  //       author.toLowerCase().includes(stdQuery) ||
  //       (tags && tags.join("").toLowerCase().includes(stdQuery))
  //     )
  //   })

  //   setState({
  //     query,
  //     filteredData,
  //   })
  // }

  const { filteredData, query } = state
  const filteredProjets = query !== "" ? filteredData : allPosts

  return (
    <PageLayout pageName="projet">
      <SEO title="Projets" />
      <PageTitle title="Projets" />
      <Container className="p-3 w-auto text-left d-flex flex-wrap justify-content-center">
        {filteredProjets.map(({ node }, key) => (
          <ProjectLink
            key={key}
            to={node.fields.slug}
            title={node.frontmatter.title}
            subtitle={node.frontmatter.date}
            excerpt={node.excerpt}
            featuredImage={featuredImageMap[node.fields.slug]}
          />
        ))}
      </Container>
    </PageLayout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/projets/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            description
            tags
            author
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
    allFile(
      filter: {
        extension: { eq: "png" }
        relativePath: { regex: "/feature/" }
        relativeDirectory: { regex: "/content/projets/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }
  }
`
